import { Link } from "gatsby";
import React from "react";
import Container from "~components/common/Container";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";

interface BreadcrumbProps {
	pages: {
		name: string;
		to: string;
		current?: boolean;
	}[];
}

const Breadcrumb = ({ pages }: BreadcrumbProps) => (
	<div className="bg-primary-100">
		<Container noMargin className="py-4">
			<nav aria-label="Breadcrumb">
				<ol className="flex items-center space-x-4">
					<li>
						<div>
							<Link to="/" className="text-gray-600 hover:text-primary">
								<HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
								<span className="sr-only">Startseite</span>
							</Link>
						</div>
					</li>

					{pages.map((page, i) => (
						<li key={page.name}>
							<div className="flex items-center">
								<ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-600 " aria-hidden="true" />
								<Link
									to={page.to}
									className="ml-4 text-sm font-medium text-gray-600 hover:text-primary"
									aria-current={page.current ? "page" : undefined}
								>
									{page.name}
								</Link>
							</div>
						</li>
					))}
				</ol>
			</nav>
		</Container>
	</div>
);

export default Breadcrumb;
