import { graphql } from "gatsby";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

// Types
import type { Article } from "~types/Article";

// Components
import H1 from "~components/typography/H1";
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import Breadcrumb from "~components/common/Bradcrumb";
import Button from "~components/common/Button";

export default function Page({ data, location }) {
	const { article }: { article: Article } = data;
	const image = article?.featuredImage?.node?.localFile ? getImage(article.featuredImage.node.localFile) : undefined;
	const text = parse(article.content);

	return (
		<Layout
			location={location}
			title={article.title}
			desc={article.excerpt}
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${article.facebook.childImageSharp.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={article.facebook.childImageSharp.gatsbyImageData.width}
			// imageFacebookHeight={article.facebook.childImageSharp.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${article.twitter.childImageSharp.gatsbyImageData.images.fallback.src}`}
			ogType="article"
		>
			<Breadcrumb
				pages={[
					{ name: "Blog", to: "/blog/" },
					{ name: article.title, to: `/blog${article.uri}`, current: true },
				]}
			/>
			<Container>
				<header className="text-center">
					{/* <div>
						{article.tags.split(",").map((tag) => (
							<div key={tag} className="mb-2 mr-2 inline-block rounded bg-primary-100 px-2 py-1 text-xs">
								{tag}
							</div>
						))}
					</div> */}
					<time dateTime={article.date}>{article.dateGmt}</time>
					<H1 className="mb-8">{article.title}</H1>
					<div className="mb-8 text-center">
						{image ? (
							<GatsbyImage image={image} alt={`${article.title} | Titelbild`} title={`${article.title} | Titelbild`} />
						) : null}
					</div>
				</header>
				<div className={`prose prose-lg relative mx-auto bg-white p-8 ${image ? "md:-mt-24" : ""}`}>{text}</div>
				<div className="my-5 text-center">
					<Button to="/blog/" text="Zu weiteren Artikeln" />
				</div>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	query ($id: String) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		article: wpPost(id: { eq: $id }) {
			dateGmt(formatString: "DD.MM.YYYY")
			title
			uri
			excerpt
			id
			content
			featuredImage {
				node {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 640, layout: FULL_WIDTH, aspectRatio: 1.5)
						}
					}
					facebook: localFile {
						childImageSharp {
							gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
						}
					}
					twitter: localFile {
						childImageSharp {
							gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
						}
					}
				}
			}
		}
	}
`;
